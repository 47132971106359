import Head from "next/head";
import * as React from "react";
import { Fragment } from "react";
import ToResolveClassNameError from "../../components/hoc/ToResolveClassNameError";
import Login from "../../components/login/Login";
import AppBar from "../../components/shared/SharedNavbar/SharedNavbar";
import SharedSnackbar from "../../components/shared/SharedSnackbar/SharedSnackbar";
import * as config from "../../config/config";

const LoginPage = (props) => {
  let metaTitle = "Login - NaikGaji";
  let metaDescription =
    "Cari peluang pekerjaan yang sesuai dengan pengalaman dan kemahiran anda. Jom naik gaji!";

  return (
    <ToResolveClassNameError>
      <Fragment>
        <Head>
          {/* <!-- Primary Meta Tags --> */}
          <title>{metaTitle}</title>
          <meta name="title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" key={"ogType"} />
          <meta
            property="og:url"
            content="https://naikgaji.com/login"
            key="ogUrl"
          />
          <meta property="og:title" content={metaTitle} key="ogTitle" />
          <meta
            property="og:description"
            content={metaDescription}
            key="ogDescription"
          />
          <meta
            property="og:image"
            content={config.assetDomain + "/images/logo/naikgaji-meta-img.png"}
            key="ogImage"
          />
          {/* <!-- Twitter --> */}
          <meta
            property="twitter:card"
            content="summary_large_image"
            key="twitterCard"
          />
          <meta
            property="twitter:url"
            content="https://naikgaji.com/login"
            key="twitterUrl"
          />
          <meta
            property="twitter:title"
            content={metaTitle}
            key="twitterTitle"
          />
          <meta
            property="twitter:description"
            content={metaDescription}
            key="twitterDescription"
          />
          <meta
            property="twitter:image"
            content={config.assetDomain + "/images/logo/naikgaji-meta-img.png"}
            key="twitterImage"
          />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          <link rel="canonical" href="https://naikgaji.com/login" />
        </Head>
        <AppBar />
        <Login />
        <SharedSnackbar />
      </Fragment>
    </ToResolveClassNameError>
  );
};

export default LoginPage;
